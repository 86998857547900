<template>
  <div>
    <PageTopNavigation :items="topNav" item-width="w-40" />
    <router-view />
  </div>
</template>

<script>
import PageTopNavigation from "@/components/atom/PageTopNavigation";
import { topNav } from "@/data/material/dashboard";
export default {
  data() {
    return {
      topNav: topNav,
    };
  },
  components: {
    PageTopNavigation,
  },

};
</script>

<style></style>
