export const fromPlatform = [
    { name: 'ALL', path: 'material.dashboard.fromPlatform.all', query: 'all' },
    { name: 'PDF Files', path: 'material.dashboard.fromPlatform.pdfFile', query: 'pdf-file' },
    { name: 'Word Files', path: 'material.dashboard.fromPlatform.wordFile' , query: 'word-file' },
    { name: 'Image Files', path: 'material.dashboard.fromPlatform.imageFile' , query: 'image-file' },
    { name: 'Audio Files', path: 'material.dashboard.fromPlatform.audioFile' , query: 'audio-file' },
    { name: 'Video Files', path: 'material.dashboard.fromPlatform.videoFile' , query: 'video-file' },
];


export const fromStudent = [
    { name: 'ALL', path: 'material.dashboard.fromStudent.all', query: 'all' },
    { name: 'PDF Files', path: 'material.dashboard.fromStudent.pdfFile', query: 'pdf-file' },
    { name: 'Word Files', path: 'material.dashboard.fromStudent.wordFile' , query: 'word-file' },
    { name: 'Image Files', path: 'material.dashboard.fromStudent.imageFile' , query: 'image-file' },
    { name: 'Audio Files', path: 'material.dashboard.fromStudent.audioFile' , query: 'audio-file' },
    { name: 'Video Files', path: 'material.dashboard.fromStudent.videoFile' , query: 'video-file' },
];

export const topNav = [
    { icon: "flaticon-reading-book", label: "From Platform", path: "material.dashboard.fromPlatform" },
    { icon: "flaticon-reading-book", label: "From Student", path: "material.dashboard.fromStudent" },
  ];
